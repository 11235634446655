import React, { useRef } from 'react';
import emailjs from 'emailjs-com';
import { FaEnvelope } from 'react-icons/fa';

const Contact = () => {
  const address = 'J-HUB Incubator, Jawaharlal Nehru Technological University, Hyderabad, 500085';
  const encodedAddress = encodeURIComponent(address);

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_9prjcx8', 'template_g219thm', form.current, 'PFLO8eN2E-5WDK9MJ')
      .then((result) => {
          console.log('Email sent successfully:', result.text);
          alert('Your message has been sent successfully!');
      }, (error) => {
          console.error('Error sending email:', error.text);
          alert('There was an error sending your message. Please try again later.');
      });

    e.target.reset(); // Reset form after submission
  };
  console.log(encodedAddress,sendEmail)
  return (
    <>
      <div className="flex flex-col lg:flex-row items-center lg:justify-center font-sofia lg:h-screen text-white bg-gradient-to-r from-[#332E40] via-[#413E4E] to-[#054267]">
        {/* Left Side */}
        <div className="flex-1 flex flex-col items-center lg:items-start text-center lg:text-left p-6 lg:p-32 space-y-6">
          <h1 className="text-2xl lg:text-3xl tagline mb-4">GET IN TOUCH</h1>
          <p className="text-2xl lg:text-3xl font-bold text-[#6FC5F0]">
            <span className="block">Convey Your</span>
            <span className="block">Ideas to Us</span>
          </p>
          <p className="text-sm lg:text-base max-w-xs lg:max-w-sm">
            Contact us for questions, technical assistance, or collaboration opportunities via the contact information provided.
          </p>
          <div className="mt-4 space-y-4 text-center lg:text-left">
            <div className="flex items-center justify-center lg:justify-start space-x-2">
              {/* <FaPhoneAlt className='h-5 lg:h-7' /> Phone Icon */}
              {/* <p className="tagline">+91 9398641931</p> */}
            </div>
            <div className="flex items-center justify-center lg:justify-start space-x-2">
              <FaEnvelope className='h-5 lg:h-6' /> {/* Email Icon */}
              <p className="tagline">connect@centella.co.in</p>
            </div>
            <div className="relative flex items-center justify-center lg:justify-start space-x-2 group">
              {/* <FaMapMarkerAlt className='h-5 lg:h-6' /> 
              <a 
                
                target="_blank" 
                rel="noopener noreferrer" 
                className="tagline"
              >
                Hyderabad , Telangana
              </a> */}
             
            </div>
          </div>
        </div>

        {/* Right Side (Contact Form) */}
        <div className="flex-1 flex justify-center items-center p-6 lg:p-16 text-gray-700">
          <form ref={form} 
           className="w-full max-w-lg space-y-6">
            <div className="space-y-4">
              <div>
                <label className="block font-semibold tagline text-white text-start">Name</label>
                <input name="name" type="text" className="w-full border border-gray-300 p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500" required />
              </div>
              <div className="flex flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-4">
                <div className="w-full lg:w-1/2">
                  <label className="block font-semibold tagline text-white text-start">Email</label>
                  <input name="email" type="email" className="w-full border border-gray-300 p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500" required />
                </div>
                <div className="w-full lg:w-1/2">
                  <label className="block font-semibold tagline text-white text-start">Phone Number</label>
                  <input name="phone" type="text" className="w-full border border-gray-300 p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500" />
                </div>
              </div>
              <div>
                <label className="block font-semibold tagline text-white text-start">Message</label>
                <textarea name="message" className="w-full border border-gray-300 p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500" rows="4" required></textarea>
              </div>
              <div>
                <label className="block font-semibold tagline text-white text-start">Enquiry Type</label>
                <select name="enquiry_type" className="w-full border border-gray-300 p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500">
                  <option value="Media inquiries">Media inquiries</option>
                  <option value="Investors">Investors</option>
                  <option value="Platform">Platform</option>
                  <option value="Careers">Careers</option>
                </select>
              </div>
              <div className="text-center">
                <button type="submit" className="bg-transparent hover:bg-[#7467ad] border border-[#7467ad] text-white font-medium w-full py-2 px-4 rounded-lg transition duration-300">
                  SUBMIT NOW
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
     
      {/* Centered Contact Us Heading */}
      {/* <div className="absolute top-16 left-1/2 transform -translate-x-1/2 text-center">
        <h1 className="text-4xl lg:text-5xl font-bold text-white border-b-2 border-purple-500">Contact Us</h1>
      </div> */}
    </>
  );
};

export default Contact;
