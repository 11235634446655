import React from "react";
import { motion } from "framer-motion";
import { useInView } from 'react-intersection-observer';
import { Link } from "react-router-dom";

const News = () => {
    const newsItems = [
        { id: 1, imgSrc: "./news1.png", title: "Team Centella At BioAsia 2024", body: "We are thrilled to join the line up at #BioAsia2024! Eagerly anticipating the chance to..." },
        { id: 4, imgSrc: "./news4.png", title: "Team Centella at fireside chat hosted with BAGCHI LAW and STOICUS LEGAL", body: "Through multi-layered discussions, participating startups gained valuable insights into hiring talent" },
        { id: 6, imgSrc: "./news6.png", title: "Centella AI featured in The Hans India", body: "Innovation has always turned human ingenuity into tangible examples of progress" },
    ];

    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.2, 
    });

    const fadeInUp = {
        hidden: { opacity: 0, y: 50 },
        visible: { opacity: 1, y: 0, transition: { duration: 0.8, ease: "easeOut" } }
    };

    return (
        <div ref={ref} className="bg-black text-white py-12 sm:px-16 font-sofia">
            <motion.div
                className="flex justify-between items-center md:px-20 lg:px-16 px-12"
                initial="hidden"
                animate={inView ? "visible" : "hidden"}
                variants={fadeInUp}
            >
                <h2 className="text-5xl font-semibold text-[#6FC5F0]">Latest News</h2>
                <Link to="/news-list" className="text-xl text-white hover:text-[#6FC5F0]">
    More Updates &rarr;
</Link>
            </motion.div>

            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mt-8 px-12 md:px-8 lg:px-12">
                {newsItems.map((item, index) => (
                    <motion.div
                        key={item.id}
                        className="bg-gray-800 rounded-lg overflow-hidden shadow-lg"
                        initial="hidden"
                        animate={inView ? "visible" : "hidden"}
                        variants={{
                            hidden: { opacity: 0, y: 50 },
                            visible: { opacity: 1, y: 0, transition: { duration: 0.6, ease: "easeOut", delay: index * 0.2 } }
                        }}
                    >
                        <img
                            src={item.imgSrc}
                            alt={item.title}
                            className="w-full h-52 border-gray-500 border hover:border-[#6FC5F0] object-cover rounded-t-2xl"
                        />
                        <div className="p-4">
                            <h3 className="text-lg font-bold mb-2">{item.title}</h3>
                            <p className="text-sm text-gray-400 mb-4">{item.body}</p>
                            <div className="text-xs text-gray-500">2024</div>
                        </div>
                    </motion.div>
                ))}
            </div>
        </div>
    );
};

export default News;
