import React from 'react';
import brainImage from '../images/publication1.webp'

const PublicationsSection = () => {
  return (
    <section className="bg-black text-white py-5 px-10 px-4 sm:px-8 lg:px-24 xl:px-32">
      <div className="container mx-auto">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-5xl font-semibold font-sofia text-[#6FC5F0]">Our Publications</h2>
          {/* <a href="/more-publications" className="text-sm text-gray-400 hover:text-gray-200">
            More Publications &rarr;
          </a> */}
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          <div className="bg-gray-800 rounded-xl overflow-hidden shadow-lg">
            <img src={brainImage} alt="Publication 2" className="w-full  h-50 object-cover" />
            <div className="p-4">
              <h3 className="text-lg font-bold mb-2">PROTACs in the Management of Brain Cancer</h3>
              <p className="text-sm text-gray-400 mb-4">
                Cancer treatments with targeted therapy have gained immense interest due to...
              </p>
              <a href="https://www.mdpi.com/1420-3049/28/9/3698" className="text-purple-400 hover:underline">
                Read More &rarr;
              </a>
              <div className="text-xs text-gray-500 mt-4">
              Published On  25 April 2023
              </div>
            </div>
          </div>
        
        </div>
      </div>
    </section>
  );
};

export default PublicationsSection;
