import React from 'react';
import Navbar from './components/Navbar';
import HomePage from './pages/HomePage';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Contact from './pages/contact';
import AboutUs from './pages/AboutUs';
import PipelineTable from './components/PipelineTable';
import UnderConstruction from './components/UnderConstruction';
import PublicationsSection from './components/Publications';
import TermsConditions from './components/TermsCondition';
import NewsList from './pages/NewsList';
import NewsDetails from './pages/NewsDetailspage';
import PrivacyPolicy from './components/PrivacyPolice';
 
 
function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <Routes>
          <Route path="/" element={<HomePage/>} />
          <Route path="/contact" element={<Contact/>} />
          <Route path="/news-list" element={<NewsList/>}/>
          <Route path='/newsdetails' element={<NewsDetails/>}/>
          <Route path="/teams"element={<AboutUs/>} />
          <Route path="/pipeline"element={<PipelineTable/>} />
          <Route path="/publications" element={<PublicationsSection/>}/>
          <Route path='/terms' element={<TermsConditions/>}/>
          <Route path='/privacy' element={<PrivacyPolicy/>}/>
          <Route path="/case-study"element={<UnderConstruction/>} />
          <Route path='/blogs' element={<UnderConstruction/>}/>
          <Route path='/press-relase' element={<UnderConstruction/>}/>
          <Route path="/press-release"element={<UnderConstruction/>} />
          
        </Routes>
       
      </div>
    </Router>
  );
}
 
export default App;
 
 
 